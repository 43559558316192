import React, { useEffect, useState, useContext } from 'react';
import {useOnMountUnsafe} from "../../../../UseOnMountUnsafe";



const UserModuleTextField = ({ fieldData, value, handleFieldChange }) => {

    const { articleId, prefix, unit, title, showPrice } = fieldData.options;

    useOnMountUnsafe(() => {
        setTextValue(value || '');
        console.log("Value: ", value);
    });

    useEffect(() => {
        setTextValue(value || '');
    }, [value]);

    const [textValue, setTextValue] = useState('');

    const handleTextChange = (value) => {
        setTextValue(value);
        handleFieldChange(value);
    }



    return (
        <>
            {/*<ModuleDeleteButton
                deleteField={() => deleteField(fieldData.id)}
                className="absolute top-0 right-0 m-2"
            />*/}
            <div className="flex flex-col w-1/2">
                <div className="overflow-hidden text-sm font-semibold">
                    {fieldData.options.title ? fieldData.options.title : "Text-Feld"}
                </div>
                {/*<div className="mb-4">
                    <EditableText
                        initialText={fieldData.options.description ? fieldData.options.description : "Text-Field Description"}
                        onSave={(d) => onSaveField(fieldData.id, { ...fieldData.options, description: d })}
                        classes={"text-xs "}
                    />
                </div>*/}
            </div>
            <div className="flex items-center relative mt-2">
                {prefix && <span className="mr-2">{prefix}</span>}
                <div className="w-48 relative border border-gray-300 rounded">
                    <input
                        type="text"
                        className="w-full p-2 pr-8 text-right rounded" // Right-align text and add padding on the right
                        style={{ paddingRight: '2.0rem'  }} // Adjust the right padding based on whether unit is present
                        value={textValue}
                        onChange={(e) => handleTextChange(e.target.value)}
                    />
                    {unit && <span className="absolute right-2 top-1/2 transform -translate-y-1/2">{unit}</span>}
                    {!unit && <span className="absolute right-2 top-1/2 transform -translate-y-1/2">{unit}</span>}
                </div>
            </div>
            <div className="absolute right-1 bottom-1 gap-2 ml-auto ">
                <div className="text-xs font-semibold">{fieldData.options.showPrice ? fieldData.article : ""}</div>
            </div>
        </>
    );
};

export default UserModuleTextField;
