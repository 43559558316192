import React, { useEffect, useState } from "react";
import { request } from "../../axios_helper";

function ArticleModal({ show, onClose, onSubmit, article, categories, countries }) {

	const [selectedCategory, setSelectedCategory] = useState(0);
	const [selectedFile, setSelectedFile] = useState(null);
	const [imagePath, setImagePath] = useState(null);

	const emptyArticle = {
		internalId: "",
		description1: "",
		description2: "",
		categoryId: "",
	}

	// Use state to manage the form inputs
	const [formData, setFormData] = useState(emptyArticle);
	const [articleCountries, setArticleCountries] = useState([]);

	useEffect(() => {
		console.log("Article prop in modal:", article);
		setFormData(article ? article : emptyArticle);
		if (article) {
			request("GET", `/api/v1/articleCountry/articleCountry?articleId=${article.id}`)
				.then(response => {
					console.log("Article countries fetched: ", response.data);
					checkMissingArticleCountries(response.data);
				})
			if (article.imagePath) {
				setImagePath(article.imagePath);
			} else {
				setImagePath(null);
				setSelectedFile(null);
			}
		}
		else {
			setArticleCountries([]);
		}
	}, [article]);

	useEffect(() => {
		if(categories.length > 0) {
			setSelectedCategory(categories[0].id);
		}
	}, [categories]);



	useEffect(() => {
		if (imagePath) {
			request("GET", imagePath, null, null, "blob")
				.then((response) => {
					console.log("Image fetched" + imagePath);
					setSelectedFile(response.data)
				});
		}
	}, [imagePath]);

	const checkMissingArticleCountries = (loadedArticleCountries) => {
		if (!article || !countries || countries.length === 0) return;
		// check if there is a article country for every country, else create one
		console.log("Article countries: ", loadedArticleCountries);
		console.log("Countries: ", countries);
		const missingCountries = countries.filter(country => !loadedArticleCountries.find(ac => ac.countryId === country.id));
		console.log("Missing countries: ", missingCountries);
		if (missingCountries.length > 0) {
			const newArticleCountries = missingCountries.map(country => {
				return {
					countryId: country.id,
					price: "",
					articleId: article.id
				}
			});
			request("POST", "/api/v1/articleCountry/createArticleCountries", newArticleCountries)
				.then(response => {
					setArticleCountries(prevState => [...loadedArticleCountries, ...response.data]);
				});
		} else {
			setArticleCountries(loadedArticleCountries);
		}
	};

	if (!show) return null;

	// Update state whenever an input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleDeleteImage = () => {
		setSelectedFile(null);
		setImagePath(null);
		setFormData({ ...formData, imagePath: null });
	};

	const handleInputChange = (countryId, value) => {
		let decimalFound = false;
		const numericValue = value
			.replace(/[^0-9.]/g, '') // allow only digits and dots
			.replace(/\./g, match => {
				if (!decimalFound) {
					decimalFound = true;
					return match;
				}
				return ''; // remove subsequent dots
			});
		console.log("Country ID: ", countryId);
		console.log("Value: ", numericValue);
		setArticleCountries(prevState => {
			const updatedArticleCountries = prevState.map(ac => {
					if (ac.countryId === countryId) {
						ac.price = numericValue;
					}
					return ac;
				}
			);
			console.log("Updated article countries: ", updatedArticleCountries);
			return updatedArticleCountries;
		});
		return numericValue;
	};




	return (
		<div className="fixed z-10 inset-0 flex items-center justify-center">
			<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={onClose}></div>
			<div className=" bg-white rounded px-4 pt-4 z-20 relative  overflow-scroll h-[50vh] ">
				<h2 className="text-xl mb-2">Create/Edit Article</h2>
				<form onSubmit={(e) => { e.preventDefault(); onSubmit(formData, selectedFile, articleCountries); }}
					  className=" ">
				>
					<label className="block mb-2">Internal ID</label>
					<input
						type="text"
						name="internalId"
						value={formData.internalId}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<label className="block mb-2">Artikelbezeichnung 1</label>
					<input
						type="text"
						name="description1"
						value={formData.description1}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<label className="block mb-2">Artikelbezeichnung 2</label>
					<input
						type="text"
						name="description2"
						value={formData.description2}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<label className="block mb-2">Category ID</label>
					<select
						className="mt-2 block w-full rounded-md border p-2"
						value={formData.categoryId}
						name="categoryId"
						onChange={(e) => handleChange(e)}
					>
						{categories.map(category => (
							<option key={category.id} value={category.id}>
								{category.name}
							</option>
						))}
					</select>

					Preise

					<div className="rounded border border-gray-700">
						{countries.map((country, index) => (
							<div key={country.id} className={`flex items-center
							${index != 0 ? 'border-t border-gray-400' : ''}
							`}>
								<div className="w-24 p-2">
									{country.name}
								</div>
								<input
									type="text"
									id={country.id}
									name={country.id}
									value={articleCountries.length > 0 ? articleCountries.find(ac => ac.countryId === country.id)?.price : "Pricey"}
									onChange={(e) => handleInputChange(country.id, e.target.value)}
									className="inline-flex rounded-none mr-1 items-center border-l border-gray-400 justify-between p-2
									text-gray-500 bg-white  cursor-pointer hover:none outline-none text-right"
								/>
								<div className="mr-1 text-gray-500">
									€
								</div>
							</div>
						))}
					</div>

					<label className="block mb-2">Image</label>
					<input type="file" onChange={handleFileChange} />
					{selectedFile && (
						<div className="image-preview">
							<img
								src={URL.createObjectURL(selectedFile)}
								alt="Preview"
								className="w-1/2 h-1/2"
							/>
							<button
								type="button"
								className="bg-red-500 text-white px-4 py-2 rounded mt-2"
								onClick={handleDeleteImage}
							>
								Delete Image
							</button>
						</div>
					)}


					<div className="flex justify-end mt-4 sticky bottom-0 left-0 p-2 w-full bg-white">
						<button type="submit" className="bg-green-500 text-white px-4 py-2 rounded mr-2">Submit</button>
						<button type="button" className="bg-gray-300 text-black px-4 py-2 rounded" onClick={() => {
							onClose();
							setFormData(emptyArticle)}
						}>Cancel</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ArticleModal;
