import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import UserModuleField from "./UserModuleField";
import UserSelection from "./UserSelection";
import { NewConfigurationContext } from "../NewEditConfiguration";

const UserModule = ({ content, id, column, saveModule }) => {
    const moduleRef = useRef(null);
    const [rowSpan, setRowSpan] = useState(1);

    const saveContent = (content) => {
        saveModule(id, content);
    }

    useEffect(() => {
        const adjustRowSpan = () => {
            if (moduleRef.current) {
                let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
                const height = moduleRef.current.scrollHeight;
                const gridCellHeight = 0.44* vh;
                const newRowSpan = Math.ceil(height / gridCellHeight);
                console.log("height", height, "gridCellHeight", gridCellHeight, "newRowSpan", newRowSpan);
                setRowSpan(newRowSpan);
            }
        };
        // wait for the content to load, better to wait for end of animation
        setTimeout(adjustRowSpan, 200);

        adjustRowSpan();
        window.addEventListener('resize', adjustRowSpan);

        return () => {
            window.removeEventListener('resize', adjustRowSpan);
        };
    }, [content]);

    return (
        <div
            className={`relative h-full flex flex-col justify-between overflow-hidden  border-gray-300
            `}
            style={{ gridRowEnd: `span ${rowSpan}` }}
        >
            <ModuleInstance
                content={content}
                id={id}
                column={column}
                moduleRef={moduleRef}
                onSave={saveContent}
            />
            <div className="bg-gray-300 h-[1px] my-2 mx-10 shadow shadow-lg"></div>
        </div>
    );
}

const ModuleInstance = ({ content, id, column, moduleRef, onSave }) => {
    const [fields, setFields] = useState(content.fields);

    const { configJson, setConfigJson, handleInputChange} = useContext(NewConfigurationContext);



    const onSaveField = (fieldId, newValue) => {
        let found = false;
        const updatedConfigJson = configJson.map(module => {
            if (module.id === id) {
                found = true;
                let foundField = false;
                const updatedFields = module.fields.map(field => {
                    if (field.id === fieldId) {
                        foundField = true;
                        return { ...field, value: newValue };
                    }
                    return field;
                });
                if (!foundField) {
                    updatedFields.push({ id: fieldId, value: newValue });
                }
                return { ...module, fields: updatedFields };
            }
            return module;
        });
        if (!found) {
            updatedConfigJson.push({
                id: id,
                fields: [{ id: fieldId, value: newValue }]
            });
        }
        handleInputChange(updatedConfigJson);
    }

    const onSaveSelection = (value) => {
        let found = false;
        const updatedConfigJson = configJson.map(module => {
            if (module.id === id) {
                found = true;
                return { ...module, value: value };
            }
            return module;
        });
        if (!found) {
            updatedConfigJson.push({
                id: id,
                value: value
            });
        }
        handleInputChange(updatedConfigJson);
    }

    return (
        <motion.div
            ref={moduleRef}
            layout
            layoutId={id}
            className={`rounded bg-white p-3 flex flex-col overflow-scroll relative`}
        >
            <motion.div layout>
                <div className="text-base font-semibold mb-0">{content.title}</div>
                <div className="text-sm mb-1">{content.text}</div>
            </motion.div>
            {!content.selection ? (
                <div className="grid grid-cols-1 auto-rows-[6.5lvh] gap-2">
                    {fields.map((field) => (
                        <motion.div layout key={field.id}>
                            <div className="block">
                                <UserModuleField fieldDataInput={field} moduleId={id} onSaveField={onSaveField} selection={content.selection} />
                            </div>
                        </motion.div>
                    ))}
                </div>
            ) : (
               <UserSelection id={id} content={content} onSaveField={onSaveField} onSaveSelection={onSaveSelection} selection={content.selection} />
            )}
        </motion.div>
    );
};

export default UserModule;
