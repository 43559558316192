import React, { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { DropIndicator } from "./FormBuilder";
import emitter from "./eventemitter";
import { addField } from "./ModuleApi";
import ModuleField from "./moduleFields/ModuleField";
import Switch from "../../../external_elements/js/Switch";
import { DndContext, DragEndEvent, MouseSensor, useSensor } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { head, set } from 'lodash';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';



const Module = ({ content, id, column, handleDragStart, handleDragEnd, saveModule, visibleDropIndicator, order }) => {

	const moduleRef = useRef(null);
	const [rowSpan, setRowSpan] = useState(1);

	const saveContent = (content) => {
		saveModule(id, content);
	}

	const [isHovered, setIsHovered] = useState(false);

	const createMaster = () => {
		emitter.emit('createMaster', content);
	}

	useEffect(() => {
		const adjustRowSpan = () => {
			if (moduleRef.current) {
				const height = moduleRef.current.scrollHeight;
				const gridCellHeight = window.innerHeight / 4.3 * 2;
				const newRowSpan = Math.ceil(height / gridCellHeight);
				setRowSpan(newRowSpan);
			}
		};

		adjustRowSpan();
		window.addEventListener('resize', adjustRowSpan);

		return () => {
			window.removeEventListener('resize', adjustRowSpan);
		};
	}, [content]);

	return (
		<div className={`relative h-full flex flex-col row-span-${rowSpan} overflow-hidden`}
			style={{ gridRowEnd: `span ${rowSpan}` }}
		>
			<DropIndicator beforeId={id} column={column} visible={visibleDropIndicator == id} />
			<button className="absolute right-2 transition-all top-2 bg-blue-900 hover:bg-blue-300 border border-blue-300 hover:border-blue-700
			rounded-full text-white active:text-orange-300 active:border-orange-300 text-xs px-2 py-1 z-10 mt-2"
				style={ isHovered ? { boxShadow: `inset 0 0 10px 5px rgba(0, 41, 200, 0.5)` } : { boxShadow: `inset 0 0 10px 5px rgba(100, 149, 237, 0.5)` }}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={() => createMaster()}
			>
				M
			</button>
			<ModuleInstance
				content={content}
				id={id}
				column={column}
				handleDragStart={handleDragStart}
				handleDragEnd={handleDragEnd}
				moduleRef={moduleRef}
				onSave={saveContent}
			/>
		</div>
	);
}

const ModuleInstance = ({ content, id, column, handleDragStart, handleDragEnd, moduleRef, onSave }) => {
	const [initialTitle, setInitialTitle] = useState('List');
	const [fields, setFields] = useState(content.fields);
	const [isAddingField, setIsAddingField] = useState(false);
	const [selectedFieldType, setSelectedFieldType] = useState('');
	const [dragHover, setDragHover] = useState(false);
	const [isSelection, setIsSelection] = useState(content.selection);
	const [isExclusive, setIsExclusive] = useState(content.exclusive);
	const [headField, setHeadField] = useState(content.headField);
	const [orderedFields, setOrderedFields] = useState([]);
	const [draggingField, setDraggingField] = useState(null);
	const [hoverField, setHoverField] = useState(null);


	useEffect(() => {
		setFields(content.fields);
	}, [content]);

	useEffect(() => {
		console.log("Setting ordered fields")
		console.log("Fields: ", fields);
		console.log("Head: ", headField);
		setOrderedFields(orderFields(fields, headField));
	}, [fields, headField]);

	const orderFields = (fields, head) => {
		const of = [];
		let currentField = fields.find(field => field.id == head);
		while (currentField) {
			of.push(currentField);
			currentField = fields.find(field => field.id == currentField.next);
		}
		return of;
	}



	const handleAddField = (fieldType) => {
		const [newContent, newField] = addField(id, fieldType, content);
		setFields([...fields, newField]);
		if (newField.id == newContent.headField) {
			setHeadField(newField.id);
		}
		setIsAddingField(false); // Close the field selection after adding
		onSave(newContent);
	};

	const onSaveField = (fieldId, options) => {
		const updatedFields = fields.map((field) =>
			field.id === fieldId ? { ...field, options } : field
		);

		setFields(updatedFields);

		onSave({ ...content, fields: updatedFields });
	};


	const deleteField = (fieldId) => {
		console.log(`Deleting field with id: ${fieldId}`);
		const updatedFields = fields.filter((field) => field.id !== fieldId);

		setFields(updatedFields);
		onSave({ ...content, fields: updatedFields });

		return updatedFields;
	};

	const toggleSelection = (select) => {
		setIsSelection(select);
		content.selection = select;
		onSave({ ...content });
	}

	const toggleExclusive = (exclusive) => {
		setIsExclusive(exclusive);
		content.exclusive = exclusive;
		onSave({ ...content });
	}

	const insertField = (fieldId, hoveringOverId) => {
		const field = fields.find(field => field.id == fieldId);
		const hoveringOver = fields.find(field => field.id == hoveringOverId);

		//go over linked list and check if field is before or after hoveringOver


		console.log("checking for beforebefore: ", hoveringOver.prev);
		const beforeBeforeField = fields.find(field => field.id == hoveringOver.prev);
		console.log("Inserting field: ", field, " before: ", hoveringOver, " beforeBefore: ", beforeBeforeField);
		field.next = hoveringOver.id;
		field.prev = hoveringOver.prev;
		hoveringOver.prev = field.id;
		if (beforeBeforeField && beforeBeforeField.id != field.id) {
			beforeBeforeField.next = field.id;
			if (field.id == headField) {
				content.headField = beforeBeforeField.id;
				setHeadField(beforeBeforeField.id);
			}
			console.log("option 1")
		} else if (beforeBeforeField && beforeBeforeField.id == field.id) {
			console.log("option 2")
		}
		else {
			content.headField = field.id;
			setHeadField(field.id);
			console.log("option 3")
		}
		let updatedFields = fields.map(f => f.id == field.id ? field
			: f.id == hoveringOver.id ? hoveringOver
				: beforeBeforeField && f.id == beforeBeforeField.id ? beforeBeforeField : f);
		setFields(updatedFields);
		onSave({ ...content, fields: updatedFields });
	};

	const handleDragEndEnd = (event) => {
		const { active, over } = event;

		// if active and over are the same, do nothing
		if (!active || !over || active.id == over.id ) {
			return;
		}

		let activeField = orderedFields.find(field => field.id == active.id);
		let overField = orderedFields.find(field => field.id == over.id);

		// remove active from linked list

		let beforeActive = orderedFields.find(field => field.id == activeField.prev);
		let afterActive = orderedFields.find(field => field.id == activeField.next);

		if (beforeActive) {
			beforeActive.next = activeField.next;
		}
		if (afterActive) {
			afterActive.prev = activeField.prev;
		}

		// go through order fields and find out if active is before or after over
		let currentField = orderedFields.find(field => field.id == headField);
		let isBefore = false;
		while (currentField) {
			if (currentField.id == active.id) {
				isBefore = true;
				break;
			}
			else if (currentField.id == over.id) {
				isBefore = false;
				break;
			}
			currentField = orderedFields.find(field => field.id == currentField.next);
		}

		let newHead;
		if (activeField.id == headField) {
			content.headField = activeField.next;
			newHead = activeField.next
		} else if (!isBefore && overField.id == headField) {
			content.headField = activeField.id;
			newHead = activeField.id;
		} else {
			newHead = headField;
		}

		let beforeField = orderedFields.find(field => field.id == overField.prev);
		let afterField = orderedFields.find(field => field.id == overField.next);
		if (!isBefore) {
			activeField.prev = overField.prev;
			if (beforeField) {
				beforeField.next = activeField.id;
			}
			activeField.next = overField.id;
			overField.prev = activeField.id;
		} else {
			activeField.next = overField.next;
			if (afterField) {
				afterField.prev = activeField.id;
			}
			activeField.prev = overField.id;
			overField.next = activeField.id;
		}


		let updatedFields = orderedFields.map(f => f.id == activeField.id ? activeField
			: f.id == overField.id ? overField
				: beforeField && f.id == beforeField.id ? beforeField
					: afterField && f.id == afterField.id ? afterField
						: beforeActive && f.id == beforeActive.id ? beforeActive
							: afterActive && f.id == afterActive.id ? afterActive
								: f);
		setFields(updatedFields);
		setHeadField(newHead);
		onSave({ ...content, fields: updatedFields, headField: newHead });
		setOrderedFields(orderFields(updatedFields, newHead));
	}

	const mouseSensor = useSensor(MouseSensor, {
		// Require the mouse to move by 10 pixels before activating
		activationConstraint: {
			distance: 10,
		},
	});


	return (
		<motion.div
			ref={moduleRef}
			layout
			layoutId={id}
			draggable={dragHover}
			onDragStart={(e) => handleDragStart(e, { id, content, column })}
			onDragEnd={(e) => handleDragEnd(e)}
			className={`m-1 rounded border shadow-lg bg-white px-3
                flex flex-col flex-grow overflow-scroll relative`}
		>
			<div className="absolute top-1 right-9 flex flex-col gap-1">
				<Switch data-off={"List"} data-on={"Select"} isChecked={isSelection} onChange={(checked) => toggleSelection(checked)} />
				{isSelection &&
					<Switch data-off={"Incl."} data-on={"Excl."} isChecked={isExclusive} onChange={(checked) => toggleExclusive(checked)} />
				}
			</div>
			<div className="absolute m-auto left-0 right-0 top-0 w-16 h-7 items-center
			flex items-center justify-center cursor-grab active:cursor-grabbing z-10"
				onMouseEnter={() => setDragHover(true)}
				onMouseLeave={() => setDragHover(false)}
			>
				<div className={`rounded bg-gray-900 ${dragHover ? "opacity-50" : "opacity-0"} h-2 w-10
				   transition-opacity duration-300`} />
			</div>
			<motion.div layout>
				<EditableTextArea initialText={content.title} onSave={(title) => onSave({ ...content, title })} classes={"text-base font-semibold mb-0"} maxLength={50}/>
				<EditableTextArea initialText={content.text} onSave={(text) => onSave({ ...content, text })} classes={"text-sm mb-1"} maxLength={70}/>
			</motion.div>
			<div className="grid grid-cols-1 gap-2">
				<DndContext
					onDragEnd={handleDragEndEnd}
					modifiers={[restrictToVerticalAxis]}
					sensors={[mouseSensor]}
				>
					<SortableContext items={orderedFields}>
						{orderedFields.map((field) => (
							<ModuleField fieldDataInput={field}
								key={field.id}
								onSaveField={onSaveField}
								deleteField={deleteField}
								draggingField={draggingField}
								setDraggingField={setDraggingField}
								insertField={insertField}
								setHoverField={setHoverField}
								hoverField={hoverField}
							/>
						))}
					</SortableContext>
				</DndContext>

				<motion.div className="w-full flex flex-col z-0" layout>
					{isAddingField ? (
						<div className="rounded h-full">
							<div className="grid grid-cols-3 gap-2 h-full">
								<button
									onClick={() => handleAddField('article')}
									className="flex flex-col items-center justify-center  rounded border shadow-md bg-white hover:bg-gray-100 h-1/2"
								>
									<span className="text-sm">Artikel Feld</span>
								</button>
								<button
									onClick={() => handleAddField('text')}
									className="flex flex-col items-center justify-center p-2 rounded border shadow-md bg-white hover:bg-gray-100 h-1/2"
								>
									<span className="text-sm">Text Feld</span>
								</button>
								<button
									onClick={() => handleAddField('slider')}
									className="flex flex-col items-center justify-center p-2 rounded border shadow-md bg-white hover:bg-gray-100 h-1/2"
								>
									<span className="text-sm">Slider Feld</span>
								</button>
							</div>
						</div>
					) : (
						<button
							onClick={() => setIsAddingField(true)}
							className="rounded border shadow-md bg-white hover:bg-gray-100 p-1.5 text-sm h-1/2 flex items-center justify-center"
						>
							+ Feld hinzufügen
						</button>
					)}
				</motion.div>
			</div>
		</motion.div>
	);
};

export const EditableText = ({ initialText, onSave, classes }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [text, setText] = useState(initialText);
	const inputRef = useRef(null);

	const handleDoubleClick = () => {
		setIsEditing(true);
	};

	const handleBlur = () => {
		setIsEditing(false);
		onSave(text);
	};

	const handleChange = (e) => {
		setText(e.target.value);
		//onSave(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			setIsEditing(false);
			onSave(text);
		}
	};

	return isEditing ? (
		<input
			ref={inputRef}
			type="text"
			value={text}
			onChange={handleChange}
			onBlur={handleBlur}
			onKeyDown={handleKeyDown}
			className="border rounded p-1 w-full"
			autoFocus
		/>
	) : (
		<>
			{text
				? <div onDoubleClick={handleDoubleClick} className={classes}>
					{text}
				</div>
				: <div onDoubleClick={handleDoubleClick} className={classes + " italic"}>
					Wird nicht angezeigt wenn leer
				</div>
			}
		</>
	);
};

export const EditableTextArea = ({ initialText, onSave, classes, maxLength }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [text, setText] = useState(initialText);
	const [charCount, setCharCount] = useState(initialText ? initialText.length : 0);
	const divRef = useRef(null);

	useEffect(() => {
		setText(initialText);
		setCharCount(initialText ? initialText.length : 0);
	}, [initialText]);

	const placeCaretAtEnd = (el) => {
		const range = document.createRange();
		const selection = window.getSelection();
		range.selectNodeContents(el);
		range.collapse(false);
		selection.removeAllRanges();
		selection.addRange(range);
	};

	const handleDoubleClick = () => {
		setIsEditing(true);
		setTimeout(() => {
			if (divRef.current) {
				divRef.current.focus();
				placeCaretAtEnd(divRef.current);
			}
		}, 0);
	};

	const handleBlur = () => {
		setIsEditing(false);
		const newText = divRef.current?.innerText.trim();
		setText(newText);
		onSave(newText);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			divRef.current?.blur();
		}
	};

	const handleInput = (e) => {
		const currentText = divRef.current.innerText;
		if (currentText.length > maxLength) {
			divRef.current.innerText = currentText.slice(0, maxLength);
			placeCaretAtEnd(divRef.current);
			setCharCount(maxLength);
		} else {
			setCharCount(currentText.length);
		}
	};

	const handlePaste = (e) => {
		e.preventDefault();
		const clipboardText = e.clipboardData.getData('text/plain');
		const currentText = divRef.current.innerText;
		const remainingLength = maxLength - currentText.length;
		const textToPaste = clipboardText.slice(0, remainingLength);
		document.execCommand('insertText', false, textToPaste);
	};

	return (
		<div className="relative">
			<div
				ref={divRef}
				contentEditable={isEditing}
				suppressContentEditableWarning={true}
				onDoubleClick={handleDoubleClick}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				onInput={handleInput}
				onPaste={handlePaste}
				className={`${classes}`}
				style={{
					whiteSpace: 'pre-wrap',
					outline: 'none',
					cursor: 'text',
					color: isEditing ? '#2563EB' : 'inherit',
				}}
			>
				{text ? (
					text
				) : (
					<span className="italic text-gray-500">Text hinzufügen</span>
				)}
			</div>
			{isEditing && (
				<span
					className="absolute right-0 top-0 text-xs text-gray-500"
					style={{ marginTop: '2px', marginRight: '2px' }}
				>
        </span>
			)}
		</div>
	);
};






export default Module;
