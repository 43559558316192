import React, { useState } from "react";
import ModuleArticleSelect from "./ModuleArticleSelect";
import ModuleInputCheckbox from "./ModuleInputCheckbox";
import ModuleInputText from "./ModuleInputText";

const TextFieldModal = ({ fieldData, isOpen, onClose, onSearch, articles, categories, onSaveField }) => {
    const [search, setSearch] = useState('');

    const handleSearch = () => {
        onSearch(search);
        onClose();
    };

    const onArticleSelection = (articleId) => {
        onSaveField(fieldData.id, { ...fieldData.options, articleId: articleId });
        //onClose();
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50" draggable="false">

            <div className="bg-gray-700 opacity-75 absolute inset-0 z-30" onClick={onClose}></div>

            <div className="modal-content bg-white rounded-lg p-6 z-40 relative">
                <div className="space-y-2">
                    <ModuleArticleSelect articles={articles} categories={categories} handleInputChange={onArticleSelection}
                                         articleId={fieldData.options.articleId}/>
                    <ModuleInputText
                        label="Prefix"
                        type="text"
                        value={fieldData.options.prefix}
                        //onChange={(e) => handleInputChange("prefix", e.target.value)}
                        onChange={(e) => onSaveField(fieldData.id, { ...fieldData.options, prefix: e.target.value })}
                    />
                    <ModuleInputText
                        label="Unit"
                        type="text"
                        value={fieldData.options.unit}
                        //onChange={(e) => handleInputChange("unit", e.target.value)}
                        onChange={(e) => onSaveField(fieldData.id, { ...fieldData.options, unit: e.target.value })}
                    />
                    <ModuleInputCheckbox
                        label="Show Price"
                        checked={fieldData.options.showPrice}
                        onChange={(e) => onSaveField(fieldData.id, { ...fieldData.options, showPrice: e.target.checked })}
                    />
                </div>
                <div className="flex justify-center gap-2 mt-6">
                    <button className="bg-gray-300 text-black px-4 py-2 rounded" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="bg-green-500 text-white px-4 py-2 rounded mr-2" onClick={onClose}>
                        Select
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TextFieldModal;
