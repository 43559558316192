import ClientSelectionField from "./ClientSelectionField";
import ClientTextField from "./ClientTextField";
import ClientSliderField from "./ClientSliderField";
import ClientArticleField from "./ClientArticleField";
import ClientSectionField from "./ClientSectionField";
import { useContext, useEffect, useState } from "react";
import { useOnMountUnsafe } from "../../../../UseOnMountUnsafe";
import { ConfigurationContext } from "../EditConfiguration";

function ClientField ({ fieldData }) {

	const [value, setValue] = useState(null);
	const { handleInputChange, configJson } = useContext(ConfigurationContext);


	useEffect(() => {
		const newValue = configJson.find(field => field.fieldId === fieldData.id)?.fieldValue || null;
		console.log("New Value: ", newValue);
		setValue(newValue);
	}, [configJson]);

	const handleFieldChange = (value) => {
		setValue(value);
		console.log("Value: ", value);
		console.log("FieldId: ", fieldData.id);
		handleInputChange(fieldData.id, value);
	}


	const type = fieldData.type;

	const clientFieldMap = {
		"selection": ClientSelectionField,
		"text": ClientTextField,
		"slider": ClientSliderField,
		"article": ClientArticleField,
		"section": ClientSectionField
	};

	const ClientFieldComponent = clientFieldMap[type];

	if (ClientFieldComponent) {
		return <ClientFieldComponent options={fieldData.options} id={fieldData.id} value={value} handleFieldChange={handleFieldChange}/>;
	}
}

export default ClientField;
