import React, { useEffect, useState, useContext } from 'react';
//import { ArticleContext } from "../../../../App";
import ModuleInputText from "./ModuleInputText";
import ModuleInputCheckbox from "./ModuleInputCheckbox";
import InputTextArea from "../field/InputTextArea";
import {EditableText} from "../Module";
import {EditableTextArea} from "../Module";
import ModuleArticleSelect from "./ModuleArticleSelect";
import ModuleDeleteButton from "./ModuleDeleteButton";
import ArticleFieldModal from "./ArticleFieldModal";
import TextFieldModal from "./TextFieldModal";
import { FaEdit } from "react-icons/fa";
import { ArticleContext } from "../../../../App";


const ModuleArticleField = ({ fieldData, onSaveField, deleteField, articles, categories }) => {

    const [showArticleModal, setShowArticleModal] = useState(false);


    return (
        <>
            <div className="rounded border shadow-md bg-white h-full hover:bg-gray-100 p-1 text-xs overflow-hidden">
                {/*<ModuleDeleteButton
                    deleteField={() => deleteField(fieldData.id)}
                    className="absolute top-0 right-0 m-2"
                />*/}
                <div className="flex content-center">
                    <div className="flex flex-col w-1/2">
                        <div className="overflow-hidden">
                            <EditableTextArea
                                initialText={fieldData.options.title ? fieldData.options.title : "Artikel-Feld Titel"}
                                onSave={(t) =>
                                    onSaveField(fieldData.id, { ...fieldData.options, title: t })
                                }
                                classes={"text-sm font-semibold"}
                                maxLength={70}
                            />
                        </div>
                        {/*<div className="mb-4">
                            <EditableText
                                initialText={fieldData.options.description ? fieldData.options.description : "Article-Field Description"}
                                onSave={(d) => onSaveField(fieldData.id, { ...fieldData.options, description: d })}
                                classes={"text-xs "}
                            />
                        </div>*/}
                    </div>
                    <div className="flex items-center gap-2 ml-auto">
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                ArticleNr
                            </div>
                            <div className="text-xs">
                                {fieldData.options.articleId ? fieldData.options.articleId : "-"}
                            </div>
                        </div>
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                ShowPrice
                            </div>
                            <div className="text-xs">
                                {fieldData.options.showPrice ? "Yes" : "No"}
                            </div>
                        </div>
                        <button className="relative mt-4">
                            <FaEdit size={14} onClick={() => setShowArticleModal(true)} />
                        </button>
                    </div>
                </div>

            </div>
            {
                showArticleModal &&
                <ArticleFieldModal fieldData={fieldData} articles={articles} categories={categories} onClose={() => setShowArticleModal(false)} onSaveField={onSaveField}/>
            }
        </>
    );
};

export default ModuleArticleField;

