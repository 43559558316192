import React, { useEffect, useState, useContext } from 'react';
import PriceElement from "../elements/PriceElement";
import { ArticleContext } from "../../../App";

const UserModuleArticleField = ({ fieldData }) => {

    const { articles, categories } = useContext(ArticleContext);
    const [article, setArticle] = useState(null);

    useEffect(() => {
        setArticle(articles.find(article => article.id === fieldData.options.articleId));
    }, [articles]);

    return (
        <>
            {/*<ModuleDeleteButton
                deleteField={() => deleteField(fieldData.id)}
                className="absolute top-0 right-0 m-2"
            />*/}
            <div className="flex flex-col w-1/2">
                <div className="overflow-hidden text-sm font-semibold">
                    {fieldData.options.title ? fieldData.options.title: "Artikel-Feld"}
                </div>
                {/*<div className="mb-4">
                    <EditableText
                        initialText={fieldData.options.description ? fieldData.options.description : "Article-Field Description"}
                        onSave={(d) => onSaveField(fieldData.id, { ...fieldData.options, description: d })}
                        classes={"text-xs "}
                    />
                </div>*/}
            </div>
            <div className="absolute right-1 bottom-1 gap-2 ml-auto ">
                <div className="text-xs font-semibold">{fieldData.options.showPrice ? "100 CHF" : ""}</div>
            </div>
        </>
    );
};

export default UserModuleArticleField;
