import UserModuleField from "./UserModuleField";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { NewConfigurationContext } from "../NewEditConfiguration";

const UserSelection = ({ content, onSaveField, onSaveSelection, id }) => {

	const { configJson, setConfigJson, handleInputChange} = useContext(NewConfigurationContext);
	const [selectedValues, setSelectedValues] = useState([]);

	const [value, setValue] = useState(null);
	const [fields, setFields] = useState(content.fields)

	useEffect(() => {
		const newValue = configJson.find(module => module.id === id)?.value || [];
		console.log("New Value: ", newValue);
		setValue(newValue);
	}, [configJson]);

	useEffect(() => {
		setSelectedValues(value || []);
	}, [value]);

	const handleFieldChange = (value) => {
		setValue(value);
		onSaveSelection(value);
		console.log("Value: ", value);
		//handleInputChange(id, value);
	}

	const handleRadioChange = (value) => {
		if (selectedValues.includes(value)) {
			setSelectedValues([]);
			onSaveSelection([]);
		} else {
			setSelectedValues([value]);
			onSaveSelection([value]);
		}
		//handleFieldChange([value]);
	};



	const handleCheckboxChange = (value) => {
		setSelectedValues(prevState => {
			const newSelectedValues = selectedValues.includes(value)
				? selectedValues.filter(val => val !== value)
				: [...selectedValues, value]
			handleFieldChange(newSelectedValues);
			return newSelectedValues;
		});
	}

	return (
		(content.exclusive) ? (
			<form className="grid grid-cols-1 auto-rows-[6.5lvh] gap-2">
				{fields.map((field) => (
					<motion.div layout key={field.id}>
						<div
							onClick={() => handleRadioChange(field.id)}
							className={`block rounded border ${selectedValues.includes(field.id) ? "border-blue-400" : 'border-gray-400' } hover:border-gray-700`}
						>
							<UserModuleField fieldDataInput={field} moduleId={id} onSaveField={onSaveField} selection={content.selection}/>
						</div>
					</motion.div>
				))}
			</form> )
		: (
			<form className="grid grid-cols-1 auto-rows-[6.5lvh] gap-2">
				{fields.map((field) => (
					<motion.div layout key={field.id}>
						<div
							className={`block rounded border ${selectedValues.includes(field.id) ? "border-blue-400" +
								" hover:border-blue-700" : 'border-gray-400 hover:border-gray-700' } `}
							onClick={() => handleCheckboxChange(field.id)}
						>
							<UserModuleField fieldDataInput={field} moduleId={id} onSaveField={onSaveField} selection={content.selection}/>
						</div>
					</motion.div>
				))}
			</form>
			));
}

export default UserSelection;
