import { request } from "../../../../axios_helper";
import { createField } from "./FormBuilder";

let products = [
	{ id: "1", name: "Product 1"},
	{ id: "2", name: "Product 2"}
]

let pages = [
	{ id: "1", prev: null, next: "2", productID: "1", title: "Motor", leftHead: "1", rightHead: "2"},
	{ id: "2", prev: "1", next: "3", productID: "1", title: "Rad", leftHead: "4", rightHead: null},
	{ id: "3", prev: "2", next: "4", productID: "1", title: "Stuhl", leftHead: null, rightHead: null},
	{ id: "4", prev: "3", next: "5", productID: "1", title: "Armlehne", leftHead: null, rightHead: null},
	{ id: "5", prev: "4", next: "6", productID: "1", title: "Joystick", leftHead: null, rightHead: null},
	{ id: "6", prev: "5", next: null, productID: "1", title: "Extras", leftHead: null, rightHead: null}
]

let pagesHead = "1";

export const getProducts = () => {
	return request("GET", "api/v1/product")
		.then((response) => {
			return response.data;
		})
}





let modules = [
	{ id: '1', content: { title: "Module 1", text: "Module Description", fields: [], headField: null, selection: true, exclusive: false}, column: 'left', next: null, prev: null, page: "1"},
	{ id: '2', content: { title: "Module 2", text: "Module Description", fields: [], headField: null, selection: false, exclusive: false}, column: 'right', next: "3", prev: null, page: "1"},
	{ id: '3', content: { title: "Module 3", text: "Module Description", fields: [], headField: null, selection: false, exclusive: false}, column: 'right', next: null, prev: "2", page:"1"},
	{ id: '4', content: { title: "Module 4", text: "Module Description", fields: [], headField: null, selection: true, exclusive: false}, column: 'left', next: null, prev: null, page: "2"}
]

let masterModules = [
	{ id: '1', content: { title: "Master1", text: "MasterText 1", fields:[]}},
	{ id: '3', content: { title: "Master2", text: "MasterText 2", fields:[]}},
]

//let rightHead = 3;
//let leftHead = 1;

/**
 * @description - returns the modules array and the right and left head
 * @returns {[[{next: number, prev: null, column: string, id: string, content: {text: string, title: string, fields: *[]}},{next:
 *     null, prev: number, column: string, id: string, content: {text: string, title: string, fields: *[]}},{next: null, prev:
 *     null, column: string, id: string, content: {text: string, title: string, fields: *[]}}],number,number]}
 */
export const getModules = (productId) => {
	if (!productId) {
		return request("GET", "api/v1/form-builder/modules")
			.then((response) => {
				return response.data;
			})
	} else {
		return request("GET", `api/v1/form-builder/modules?productId=${productId}`)
			.then((response) => {
				return response.data;
			})
	}
}

// master modules should only contain content and id
/**
 * @description - returns the masterModules array
 * @returns {[{id: string, content: {text: string, title: string, fields: *[]}},{id: string, content: {text: string, title:
 *     string, fields: *[]}}]}
 */
export const getMasters = () => {
	return request("GET", "api/v1/form-builder/masters")
		.then((response) => {
			return response.data;
		})
}

/**
 * @param content
 * @returns {{id: string, content}}
 * @description - adds a module to the masterModules array based on the content object only
 */
export const addMaster = (content) => {
	const module = { content: content };
	console.log("New master pre flight ", module)
	return request("POST", "api/v1/form-builder/masters", module)
		.then((response) => {
			return response.data;
		})
}

/**
 * @description - POST request to the backend to add a new module
 * @param module
 */
export const addModule = (module) => {
	return request("POST", "api/v1/form-builder/modules", module)
		.then((response) => {
			return response.data;
		})
};

export const updateModule = (module) => {
	return request("PUT", "api/v1/form-builder/modules", module)
		.then((response) => {
			console.log("updated ", response.data)
			return response.data;
		})
};

export function onModulesReordered(modules, pageId, column) {

	const orderedModuleIds = modules.map(module => module.id);

	const orderUpdate = {
		pageId: pageId,
		column: column,
		orderedModuleIds: orderedModuleIds
	};

	// Send orderUpdate to backend via API call
	request("PUT", "api/v1/form-builder/modules/reorder", orderUpdate)
		.then(response => {
			if (response.status !== 200) {
				console.log("Error in reordering modules")
				console.log("response: ", response)
				return Promise.reject("Error");
			} else {
				return;
			}
		});
}

export const removeFromList = (module) => {
	return request("PUT", "api/v1/form-builder/modules/remove", module)
		.then((response) => {
			return response.data;
		})
}

/**
 * @param {string} id - id of the module to be removed
 * @returns {void}
 * @description - removes a module from the modules array updates the modules array updates the rightHead or leftHead if the
 * removed module was the head, only in backend, frontend should be updated by the caller
 */
export const removeModule = (id) => {
	return request("DELETE", `api/v1/form-builder/modules/${id}`)
		.then((response) => {
			if (response.status !== 200) {
				console.log("Error in removing module")
				console.log("response: ", response)
				return false
			} else {
				return true;
			}
		})
}

export const updateHead = (pageId, column, id) => {
	return request("PUT", `api/v1/form-builder/pages/head?pageId=${pageId}&head=${column}${id ? `moduleId=${id}` : ""}`)
		.then((response) => {
			return response.data;
		})
};


export const addField = (moduleId, type, content, articleId, title,unit, prefix, showPrice) => {

	const fields = content.fields;
	const options = {
		articleId: articleId,
		title: title,
		description: "Beschreibung",
		unit: unit,
		prefix: prefix,
		minVal: "0",
		maxVal: "100",
		intervalStep: "5",
		stepCount: "19",
		showPrice: showPrice
	}


	const lastField = fields.length == 0 ? null :
		fields.reduce((prev, current) => (prev.id > current.id) ? prev : current);
	const newId = lastField ? lastField.id + 1 : 1;
	const newField =  {
		id:  newId,
		type: type,
		options,
		prev: lastField ? lastField.id : null,
		next: null
	};

	const oldFields = fields;


	let newFields = [...oldFields, newField];

	// update the next field of the previously last field
	if (oldFields.length != 0) {
		newFields = newFields.map((f) => {
			if (f.id == lastField.id) {
				f.next = newField.id;
			}
			return f;
		});
	};

	let newContent = {...content, fields: newFields};

	// set headField if it is the first field
	if (oldFields.length == 0 || lastField == null) {
		newContent = {...newContent, headField: newField.id};
	}

	//modules = modules.filter((m) => m.id != moduleId);
	//const newModule = request("PUT", `api/v1/form-builder/modules/field`, {id: moduleId, content: newContent})
	//modules = [...modules, newModule];

	return [newContent, newField];
};

// getPages description
/**
 * @description - returns the pages array and the pagesHead
 * @returns {[{id: string, prev: string, next: string, product: string, title: string, leftHead: string, rightHead: string},{id:
 *     string, prev: string, next: string, product: string, title: string, leftHead: string, rightHead: string},{id: string,
 *     prev:
 *     string, next: string, product: string, title: string, leftHead: string, rightHead: string},{id: string, prev: string,
 *     next:
 *     string, product: string, title: string, leftHead: string, rightHead: string},{id: string, prev: string, next: string,
 *     product: string, title: string, leftHead: string, rightHead: string},{id: string, prev: string, next: string, product:
 *     string, title: string, leftHead: string, rightHead: string}],string}
 */
export const getPages = async () => {
	return request("GET", "api/v1/form-builder/pages")
		.then((response) => {
			return response.data;
		})
}

export const getPagesHead = async (productId) => {
	return request("GET", `api/v1/product/headPage/${productId}`)
		.then((response) => {
			return response.data;
		})
}

export const getHeadOfPageColumn = async (pageId, column) => {
	return request("GET", `api/v1/form-builder/pages/head?pageId=${pageId}&column=${column}`)
		.then((response) => {
			return response.data;
	})
}


export const updatePage = (page) => {
	return request("PUT", "api/v1/form-builder/pages", page)
		.then((response) => {
			return response.data;
		})
};

export const addPage = async (newPage) => {
	return request("POST", "api/v1/form-builder/pages", newPage)
		.then((response) => {
			return response.data;
		})
};

export const updatePagesHead = (productId, newPagesHeadId) => {
	return request("POST", `api/v1/product/headPage/${productId}?headPageId=${newPagesHeadId}`)
		.then((response) => {
			return response.data;
		})
};

export const removePage = (pageId) => {
	return request("DELETE", `api/v1/form-builder/pages/${pageId}`)
		.then((response) => {
			return response.data;
		})
}



//addField('1', 'article', "1", "Article");
//addField('1', 'text', "2", "Text");
//addField('1', 'slider', "3", "Slider");

