import ModuleArticleField from "./ModuleArticleField";
import React, { useContext, useEffect, useRef, useState } from "react";
import ModuleTextField from "./ModuleTextField";
import ModuleSliderField from "./ModuleSliderField";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ArticleContext } from "../../../../App";


const ModuleField = ({ fieldDataInput, onSaveField, deleteField, draggingField, setDraggingField, insertField, setHoverField, hoverField }) => {

    const type = fieldDataInput.type;
    const [fieldData, setFieldData] = useState(fieldDataInput)
    const [dragHover, setDragHover] = useState(false);
    const dragImageRef = useRef(null);

    const { articles, categories } = useContext(ArticleContext);

    const fieldComponentMap = {
        "article": ModuleArticleField,
        "text": ModuleTextField,
        "slider": ModuleSliderField
    };

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: fieldDataInput.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleDragStart = (e) => {
    }

    const handleDragOver = (e) => {
        if (draggingField && draggingField != fieldDataInput.id) {
            setHoverField(fieldDataInput.id);
        }
    }

    useEffect(() => {
        if (hoverField && hoverField == fieldDataInput.id) {
            insertField(draggingField, fieldDataInput.id);
        }
    }, [hoverField]);

    const handleDragEnd = (e) => {
        setDraggingField(null);
    }

    const FieldComponent = fieldComponentMap[type];
    if (FieldComponent) {
        return (
            <div
                ref={setNodeRef}
                {...attributes}
                {...listeners}
                style={style}
                key={fieldDataInput.id}
                className={`relative h-12 ${draggingField == fieldDataInput.id ? "opacity-0" : ""}`}
            // draggable={dragHover}
            // onDragStart={handleDragStart}
            // onDragOver={handleDragOver}
            // onDragEnd={handleDragEnd}
            >
                <div className="absolute m-auto left-0 right-0 top-0 w-16 h-7 items-center
			    flex items-center justify-center cursor-grab active:cursor-grabbing"
                    onMouseEnter={() => setDragHover(true)}
                    onMouseLeave={() => setDragHover(false)}
                >
                    <div className={`rounded bg-gray-900 ${dragHover ? "opacity-50" : "opacity-0"} h-2 w-10
				   transition-opacity duration-300`} />
                </div>
                <FieldComponent
                    fieldData={fieldData}
                    onSaveField={(id, options) => {
                        const updatedFieldData = { ...fieldData, options };
                        setFieldData(updatedFieldData);
                        onSaveField(id, options);
                    }}
                    deleteField={deleteField}
                    articles={articles}
                    categories={categories}
                />
            </div>
        );
    }
    return null;
};

export default ModuleField;
