import React, { useContext, useEffect, useState } from "react";
import { request } from "../../../axios_helper";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import {EditableTextArea} from "../configuration/create/Module";
import imageVivaGrand from "../../../assets/VivaGrand.png";
import imageVivaGrandS from "../../../assets/VivaGrandS.png";
import imageVivaGrandM from "../../../assets/VivaGrandM.png";
import imageVivaPlus from "../../../assets/VivaPlus.png";
import imageVivaJunior from "../../../assets/VivaJunior.png";
import imageVivaGrandSM from '../../../assets/VivaGrandSM.png';
import imageVivaJuniorS from '../../../assets/VivaJuniorS.png';
import imageVivaGrandF from '../../../assets/VivaGrandF.png';
import { getModules } from "../configuration/create/ModuleApi";

function getNewFieldValue(field) {
	if (field.type === "text") {
		return "";
	} else if (field.type === "slider") {
		return field.options.min;
	} else if (field.type === "selection") {
		return [];
	} else if (field.type === "article") {
		return "";
	}
}

function generateConfigJson(product) {
	return product.fields.map(field => {
		return {
			fieldId: field.id,
			fieldValue: getNewFieldValue(field)
		};
	});
}

async function generateNewConfigJson(product) {
	const modules = await request("GET", `api/v1/form-builder/modules?productId=${product.id}`)
		.then((response) => {
			return response.data;
		})
	console.log("Modules", modules);
	return modules.map(module => {
		return {
			id: module.id,
			value: [],
			fields: module.content.fields.map(field => {
				return {
					id: field.id,
					articleId: field.options.articleId,
					value: getNewFieldValue(field)
				};
			})
		};
	});
}

function SelectProduct({ }) {
	const [products, setProducts] = useState([]);
	const [modules, setModules] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);

	const {auth} = useContext(AuthContext);

	const navigate = useNavigate();

	useEffect(() => {
		request("GET", "/api/v1/product")
			.then(response => {
				setProducts(response.data.sort((a, b) => a.id - b.id));
			});
	}, []);

	function finalizeSelection() {
		if (auth === null || !auth?.token || selectedProduct === null) {
			return;
		}

		const newConfigJson = generateNewConfigJson(products.find(p => p.id === selectedProduct)).then((response) => {
			console.log("NewConfigJson", response);
			const newConfig = {
				accountId: auth.id,
				productId: selectedProduct,
				title: "",
				description: "",
				clientName: "",
				configJson: JSON.stringify(response)
			};

			console.log("ConfigJson", newConfig.configJson);

			console.log("newConfig", newConfig);

			request("POST", `/api/v1/configuration`, newConfig)
				.then(response => {
					console.log("Configuration created");
					navigate(`/u/configurations/${response.data.id}`);
				});
		} );

	}
	const imageMap = {
		VivaGrand: imageVivaGrand,
		VivaGrandS: imageVivaGrandS,
		VivaGrandM: imageVivaGrandM,
		VivaPlus: imageVivaPlus,
		VivaJunior: imageVivaJunior,
		VivaJuniorS: imageVivaJuniorS,
		VivaGrandSM: imageVivaGrandSM,
		VivaGrandF: imageVivaGrandF,
	};

	return (
		<div>
			<div className="grid lg:grid-cols-3 gap-4 p-4">
				{products.map(product => (
					<div key={product.id}
						 className={`border-2 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 h-[380px] flex ${selectedProduct === product.id ? 'border-red-500' : 'border-gray-300'}`}
						 onClick={() => setSelectedProduct(product.id)}>
						{/* Left side: product name, image, and image selection buttons */}
						<div className="w-1/2 pr-4">
							<div className="text-lg font-semibold">{product.name}</div>
							<img
								src={imageMap[product.image] || imageVivaGrand}
								alt={product.name}
								className="w-full h-auto object-cover mt-3 "
							/>
						</div>
						{/* Right side: editable product description */}
						<div className="w-2/5 mt-5">
							<div className="h-1/2">
							  <span style={{fontSize: '16px', alignSelf: 'center'}}>
								<div className="block text-gray-700 font-medium">{product.description}</div>
							  </span>
							</div>
							<div className="h-1/3 text-md font-bold mt-3">
								<div className="">{product.bullet1}</div>
								<div className="">{product.bullet2}</div>
								<div className="">{product.bullet3}</div>
								<div className="">{product.bullet4}</div>
							</div>
							{product.link && product.link.includes("www") && (
								<a
									href={product.link}
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-600"
								>
									Mehr Infos
								</a>
							)}


						</div>
					</div>
				))}
			</div>
			<div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
				<button
					onClick={finalizeSelection}
					disabled={selectedProduct === null}
					className={`rounded-full flex items-center justify-center text-white disabled:bg-gray-300 ml-2 ${selectedProduct !== null ? 'bg-red-600' : 'bg-gray-300'}`}
					style={{ width: '50px', height: '50px', fontSize: '24px' }}
				>
					&#x2192; {/* Right arrow */}
				</button>
			</div>
		</div>
	);
}

export default SelectProduct;

export { generateConfigJson, getNewFieldValue };



