import React from 'react';
import { EditableTextArea } from "./Module";
import { request } from "../../../../axios_helper";
import imageVivaGrandM from '../../../../assets/VivaGrandM.png';
import imageVivaGrand from '../../../../assets/VivaGrand.png';
import imageVivaGrandS from '../../../../assets/VivaGrandS.png';
import imageVivaJunior from '../../../../assets/VivaJunior.png';
import imageVivaPlus from '../../../../assets/VivaPlus.png';
import imageVivaGrandSM from '../../../../assets/VivaGrandSM.png';
import imageVivaJuniorS from '../../../../assets/VivaJuniorS.png';
import imageVivaGrandF from '../../../../assets/VivaGrandF.png';

const ProductInfo = ({ currentProduct, setCurrentProduct }) => {

    const saveProduct = (id, param2) => {
        console.log("saveProduct called with:", id, param2);
        const updatedProduct = { ...currentProduct, ...param2 };
        request("PUT", `/api/v1/product/${id}`, updatedProduct)
            .then((res) => {
                console.log("Response from saveProduct:", res.data);
                setCurrentProduct(res.data);
            })
            .catch((err) => {
                console.log("Error in saveProduct:", err);
            });
    };

    const imageMap = {
        VivaGrand: imageVivaGrand,
        VivaGrandS: imageVivaGrandS,
        VivaGrandM: imageVivaGrandM,
        VivaPlus: imageVivaPlus,
        VivaJunior: imageVivaJunior,
        VivaJuniorS: imageVivaJuniorS,
        VivaGrandSM: imageVivaGrandSM,
        VivaGrandF: imageVivaGrandF,
    };

    const imageOptions = [
        { label: "Viva Grand", value: "VivaGrand" },
        { label: "Viva Grand S", value: "VivaGrandS" },
        { label: "Viva Grand M", value: "VivaGrandM" },
        { label: "Viva Plus", value: "VivaPlus" },
        { label: "Viva Junior", value: "VivaJunior" },
        { label: "Viva Junior S", value: "VivaJuniorS" },
        { label: "Viva Grand F", value: "VivaGrandF" },
        { label: "Viva Grand SM", value: "VivaGrandSM" },
    ];

    return (
        <div className="grid lg:grid-cols-3 gap-4 p-4">
            <div className="border-2 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 h-[380px] border-red-500 flex">
                {/* Left side: product name, image, and image selection buttons */}
                <div className="w-1/2 pr-4 h-full flex flex-col justify-between">
                    <EditableTextArea
                        initialText={currentProduct.name || "Click to change Product Title"}
                        onSave={(t) => saveProduct(currentProduct.id, {name: t})}
                        classes={"text-lg font-semibold"}
                        maxLength={18}
                    />
                    <div className="">
                        <select
                            value={currentProduct.image || ""}
                            onChange={(e) => {
                                console.log("Setting image to", e.target.value);
                                saveProduct(currentProduct.id, {image: e.target.value});
                            }}
                            className="border rounded p-2 text-sm"
                        >
                            {imageOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <img
                        src={imageMap[currentProduct.image] || imageVivaGrand}
                        alt={currentProduct.name}
                        className="mt-3 max-h-[280px] object-contain"
                    />

                </div>
                {/* Right side: editable product description */}
                <div className="w-2/5 mt-5">
                    <div className="h-1/2">
                      <span style={{fontSize: '16px', alignSelf: 'center'}}>
                        <EditableTextArea
                            initialText={currentProduct.description || "Add product description"}
                            onSave={(t) => saveProduct(currentProduct.id, {description: t})}
                            classes={"text-black"}
                            maxLength={150}
                        />
                      </span>
                    </div>
                    <div className="h-1/3 text-md font-bold mt-3">
                        <EditableTextArea
                            initialText={currentProduct.bullet1}
                            onSave={(t) => saveProduct(currentProduct.id, {bullet1: t})}
                            classes={"text-black"}
                            maxLength={25}
                        />
                        <EditableTextArea
                            initialText={currentProduct.bullet2}
                            onSave={(t) => saveProduct(currentProduct.id, {bullet2: t})}
                            classes={"text-black"}
                            maxLength={25}
                        />
                        <EditableTextArea
                            initialText={currentProduct.bullet3}
                            onSave={(t) => saveProduct(currentProduct.id, {bullet3: t})}
                            classes={"text-black"}
                            maxLength={25}
                        />
                        <EditableTextArea
                            initialText={currentProduct.bullet4}
                            onSave={(t) => saveProduct(currentProduct.id, {bullet4: t})}
                            classes={"text-black"}
                            maxLength={25}
                        />


                    </div>
                    <div className="text-sm">Link für mehr Infos:</div>
                    <EditableTextArea
                        initialText={currentProduct.link || "Link hinzufügen"}
                        onSave={(t) => saveProduct(currentProduct.id, {link: t})}
                        classes={"text-black text-[8px]"}
                        maxLength={80}
                    />
                </div>
            </div>
        </div>
            );
    };

export default ProductInfo;


