import { useContext, useEffect, useState } from "react";
import { request } from "../../../axios_helper";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import { AuthContext } from "../../AuthProvider";
import useAuth from "../../../hooks/useAuth";


function Configurations () {

	const [configurations, setConfigurations] = useState([]);

	const { auth, setCurrentUser } =  useAuth();

	const navigate = useNavigate();

	const fetchConfigurations = async () => {
		//TODO: implement this

	};

	//useEffect(() => {
	//	setUser(auth?.getUser());
	//	setLoading(false);
	//} , [auth]);

	useEffect(() => {
		if (auth === null) {
			return;
		}
		request("GET", `/api/v1/configuration/user/${auth.id}`)
			.then(response => {
				setConfigurations(response.data);
			});
		console.log("auth: ", auth)
	}, [auth]);

	useEffect(() => {
		console.log("auth: ", auth)
		console.log("Configurations: ", configurations);
	}, [configurations]);


	function handleNewConfiguration(param) {
		navigate(`/u/new-config`);
	}

	function deleteConfiguration(configurationId) {
		// pop up a confirmation dialog
		if (!window.confirm("Wollen Sie diese Konfiguration löschen?")) {
			return;
		}
		request("DELETE", `/api/v1/configuration/${configurationId}`)
			.then(response => {
				console.log("Configuration deleted");
				setConfigurations(configurations.filter(conf => conf.id !== configurationId));
			}
		);
	}

	const getStatusDisplay = (status) => {
		switch (status) {
			case "SENT":
				return <span className="text-yellow-500">Abgesendet</span>;
			case "ACCEPTED":
				return <span className="text-green-500">Bestätigt</span>;
			case "REVISION_REQUESTED":
				return <span className="text-red-500">Zur Überarbeitung</span>;
			case "REJECTED":
				return <span className="text-red-500">Abgelehnt</span>;
			default:
				return <span className="text-red-500">In Bearbeitung</span>;
		}
	};


	return (
		<div className="bg-white p-2 font-source-sans-pro">
			<h2 className="mb-4 text-3xl font-bold">
				Meine Konfigurationen
			</h2>

			<table className="min-w-full leading-normal" key={configurations.length}>
				<thead>
				<tr>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Kundenname
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Stuhl
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Status
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Datum
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
					</th>
				</tr>
				</thead>
				<tbody>
				{configurations && configurations.length > 0 && configurations.map((configuration, index) => (
					<tr
						key={configuration.id}
						className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
					>
						<td className="py-3 px-5 border-b text-sm">
							{configuration.clientName}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{configuration.productId}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{getStatusDisplay(configuration.status)}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{moment(configuration.updatedAt).format("DD.MM.YYYY HH:mm")} Uhr
						</td>
						<td className="py-3 px-5 border-b text-sm">
							<button
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
								onClick={() => navigate(`/u/configurations/${configuration.id}`)}
							>
								Bearbeiten
							</button>
						</td>
						<td className="py-3 px-5 border-b text-sm">
							<button
								className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
								onClick={() => deleteConfiguration(configuration.id)}
							>
								-
							</button>
						</td>
					</tr>
				))}
				</tbody>
			</table>
			<div className="flex justify-end p-4">
				<button
					className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
					onClick={() => handleNewConfiguration(null)}
				>
					+
				</button>
			</div>
		</div>
	)
}

export default Configurations
